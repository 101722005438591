import React, {useState, useEffect, useRef} from "react"
import { graphql } from "gatsby"
import { Link as GatsbyLink } from 'gatsby'
import { Box, 
   Link, 
   Heading,
   Text, 
   Input,
   FormControl,
   FormLabel,
   Hstack,
   Stack,
   InputGroup,
   InputLeftAddon,
   InputRightAddon,
   NumberInputStepper,
   NumberIncrementStepper,
   NumberDecrementStepper,
   NumberInput,
   NumberInputField,
   Center,
   Tooltip,
   Button,
   HStack,
   VStack,
   Flex,
   Wrap,
   WrapItem,
   Tabs,
   TabList,
   Tab,
   TabPanels,
   TabPanel,
   RadioGroup,
   Radio,
   Tag
} from "@chakra-ui/react"
import { Icon } from '@chakra-ui/icons'
import { CgCalculator } from "react-icons/cg"
import { AiOutlineApartment,AiFillTags,AiOutlineFieldTime } from "react-icons/ai"
import { BsArrowCounterclockwise } from "react-icons/bs"
import { GrUserManager } from "react-icons/gr"

import axios  from 'axios';
import Parser from "html-react-parser"

import Layout from '../components/Layout.js';
import Seo from '../components/seo_metadata.js';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const useAxiosTest = () =>{
   const [response, setResponse] = useState(null);
   const [response1, setResponse1] = useState(null);
   const [error, setError] = useState(null);

   useEffect(() =>{
      axios.post('https://apitest.yt-search.net/index.php', {
         type: "retjwt",
         auth0userid: "auth0userid",
         auth0email: "exampl@mail.com"
      })
      .then( (response) => {
         setResponse(response.data)
         // console.log(response);

         axios.post('https://apitest.yt-search.net/index.php', {
         type: "SecretDB",
         post_id: 46596,
         jwt: response.data.jwt
         })
         .then( (response) => {
         setResponse1(response.data)
         // console.log(response)
         });

      }).catch( (error) => {
         setError(error)
         console.error(error);
      });
   },[]);

   return {response, error, response1}
}


// 小数点n位までを残す関数 (四捨五入)
const floatFormat1 = ( number, n ) => {
   var _pow = Math.pow( 10 , n ) ;
   return Math.ceil( number * _pow ) / _pow ;
}

const KeisanTool = ({get_pachi_kisyu_seinou, get_pachi_atrisyubetu}) => {

   const [borderCalResult, setBorderCalResult] = useState();
   const refHajime_round_dedama = useRef(null);
   const refKakuhen_jitan_base = useRef(null);
   const refKoatari_rush_bese = useRef(null);
   const [simple_boder, setSimple_boder] = useState();

   const [kitaichiCalResult, setKitaichiCalResult] = useState();
   const refS_koukantamasu = useRef(null);
   const refS_motidamahiritu = useRef(null);
   const refS_kaitenritu = useRef(null);
   const refS_tujyoukaitensu = useRef(null);
   let simple_kitaiti;

   // console.log(get_pachi_kisyu_seinou);
   if(!get_pachi_kisyu_seinou) return "";
   if(!get_pachi_atrisyubetu) return "";

   const pachi_kisyu_seinou = 
      get_pachi_kisyu_seinou
      .split(",")
      .map(val=>parseFloat(val));
   // console.log(pachi_kisyu_seinou);

   const pachi_atrisyubetu = 
      get_pachi_atrisyubetu
      .split("|")
      .map( 
         val => val
         .split(",")
         .map((val,index) => {
            if(index==0) {
               return val
            }else{
               return parseFloat(val)
            }
         })
      );
   // console.log(pachi_atrisyubetu);


   let p_heikidedam = 0;
   let p_heikidedam_total = 0;
   let p_densapohiritu = 0;
   let p_通常時短電サポ比率 = 0;
   let p_突然時短電サポ比率 = 0;
   let p_遊タイム時短電サポ比率 = 0;
   let p_小当りRUSH比率 = 0;
   let p_確変電サポ比率 = 0;

   switch (pachi_kisyu_seinou.length) {
      case 8:
         p_heikidedam = pachi_kisyu_seinou[0];
         p_heikidedam_total = pachi_kisyu_seinou[1];
         p_densapohiritu = pachi_kisyu_seinou[2] || 0;
         p_通常時短電サポ比率 = pachi_kisyu_seinou[3];
         p_突然時短電サポ比率 = pachi_kisyu_seinou[4];
         p_遊タイム時短電サポ比率 = pachi_kisyu_seinou[5];
         p_小当りRUSH比率 = pachi_kisyu_seinou[6];
         p_確変電サポ比率 = pachi_kisyu_seinou[7];
         p_densapohiritu = p_通常時短電サポ比率 + p_突然時短電サポ比率 + p_遊タイム時短電サポ比率 + p_確変電サポ比率;
         break;
      case 7:
         p_heikidedam = pachi_kisyu_seinou[0];
         p_heikidedam_total = pachi_kisyu_seinou[1];
         p_densapohiritu = pachi_kisyu_seinou[2] || 0;
         p_通常時短電サポ比率 = pachi_kisyu_seinou[3];
         p_突然時短電サポ比率 = pachi_kisyu_seinou[4];
         p_遊タイム時短電サポ比率 = pachi_kisyu_seinou[5];
         p_小当りRUSH比率 = pachi_kisyu_seinou[6];
         p_densapohiritu = p_通常時短電サポ比率 + p_突然時短電サポ比率 + p_遊タイム時短電サポ比率;
         break;
      case 4:
         p_heikidedam = pachi_kisyu_seinou[0];
         p_heikidedam_total = pachi_kisyu_seinou[1];
         p_densapohiritu = pachi_kisyu_seinou[2];
         p_小当りRUSH比率 = pachi_kisyu_seinou[3];
         break;
      case 3:
         p_heikidedam = pachi_kisyu_seinou[0];
         p_heikidedam_total = pachi_kisyu_seinou[1];
         p_densapohiritu = pachi_kisyu_seinou[2] || 0;
         break;
   }

   
   
   let hajimename = "";
   let hajimededama = 0;
   let hajimehiritu = 0;
   if (pachi_atrisyubetu[0][0] != ""){
      hajimename = pachi_atrisyubetu[0][0];
      hajimededama = pachi_atrisyubetu[0][1];
      hajimehiritu = pachi_atrisyubetu[0][2];
   }

   let hajimededamatotal = p_heikidedam_total*(hajimededama/p_heikidedam);

   const buttonboder_click = () => {

      var hajime_round_dedama = refHajime_round_dedama.current.value;
      var kakuhen_jitan_base = refKakuhen_jitan_base.current ? refKakuhen_jitan_base.current.value : 0;
      var hajime_round_total = hajimededamatotal;
      var densapo_hiritu = p_densapohiritu;
      var koatari_rush_bese = refKoatari_rush_bese.current ? refKoatari_rush_bese.current.value : 0;
      var koatari_hiritu = refKoatari_rush_bese.current ? p_小当りRUSH比率 : 0;

      var densapojizougen = (hajime_round_total*1 * densapo_hiritu*1)*kakuhen_jitan_base*1;
      var koatarijizougen = (hajime_round_total*1 * koatari_hiritu*1)*koatari_rush_bese*1;
      var kakutokudedama = hajime_round_dedama*1 + densapojizougen*1 + koatarijizougen*1;
      
      var simple_boder_local = hajime_round_total*1/(kakutokudedama*1/250);
      setSimple_boder(simple_boder_local);

      setBorderCalResult(
         <Box m={3}>
            <Center>1000円あたりの</Center>
            <Center>ボーダーライン</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(simple_boder_local, 2)}回
            </Center>
         </Box>
      );
   }

   const buttonsimplekitaiti_click = () => {
      var s_koukan_tamasu = refS_koukantamasu.current.value;
      var s_motidama_hiritu = refS_motidamahiritu.current.value;
      var s_kaiten_ritu = refS_kaitenritu.current.value;
      var s_tujyou_kaitensu = refS_tujyoukaitensu.current.value;

      var s_touka_bodar = simple_boder;

      var kaiten_tanka = ((250-(s_touka_bodar*1/s_kaiten_ritu*1*250))/s_touka_bodar*1)*(100/s_koukan_tamasu*1);
      var kankin_gap = (1/s_kaiten_ritu*1*250)*(4-(100/s_koukan_tamasu*1));
      var genkin_hiritu_son = s_tujyou_kaitensu*1*(1-s_motidama_hiritu*1/100)*kankin_gap;

      simple_kitaiti = (kaiten_tanka*s_tujyou_kaitensu)-genkin_hiritu_son;

      setKitaichiCalResult(
         <Box m={3}>
            <Center>■期待収支金額</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(simple_kitaiti, 2)}円
            </Center>
         </Box>
      )
   }

   return (
      <>
         <Box m={"5px"} w="98%">
            <Box as="fieldset" borderStyle="groove" borderWidth="2px">
               <Box as="legend" p={3} ml={6} mb={0}>ボーダーライン調査</Box>
               <Box m={3}>
                  <Text as="label" fontWeight="bold" m={3}>{hajimename}出玉</Text>
                  <HStack>
                     <Input 
                        ref={refHajime_round_dedama}
                        defaultValue={hajimededama} 
                        type="number"
                        onFocus={(e)=>e.target.select()}
                        mt={2} mb={4} textAlign="right"/>
                     <Text ml={2}>玉</Text>
                  </HStack>

                  {p_densapohiritu != 0 ?
                  <>
                  <Text as="label" fontWeight="bold" m={3}>電サポ増減数</Text>
                  <HStack>
                     <Input 
                        ref={refKakuhen_jitan_base}
                        defaultValue={0} 
                        type="number"
                        step={0.01}
                        onFocus={(e)=>e.target.select()}
                        mt={2} mb={4} textAlign="right"/>
                     <Text ml={2}>玉</Text>
                  </HStack>
                  </>
                  :
                  ""}

                  {p_小当りRUSH比率 != 0 ?
                  <>
                  <Text as="label" fontWeight="bold" m={3}>小当RUSH増減数</Text>
                  <HStack>
                     <Input 
                        ref={refKoatari_rush_bese}
                        defaultValue={0} 
                        type="number"
                        step={0.01}
                        onFocus={(e)=>e.target.select()}
                        mt={2} mb={4} textAlign="right"/>
                     <Text ml={2}>玉</Text>
                  </HStack>
                  </>
                  :
                  ""}

                  <Center>
                     <Button 
                        w={"50%"} 
                        my={2}
                        leftIcon={<Icon as={CgCalculator} color={"white"} mr={2}/>} 
                        colorScheme="teal" 
                        variant="solid"
                        onClick={buttonboder_click}
                     >
                        計算する
                     </Button>
                  </Center>
               </Box>

               {borderCalResult}

            </Box>
            
            <Box as="fieldset" borderStyle="groove" borderWidth="2px">
               <Box as="legend" p={3} ml={6} mb={0}>期待収支計算</Box>
               <Box m={3}>
                  <Text as="label" fontWeight="bold" m={3}>交換玉数</Text>
                  <HStack>
                     <Input 
                        ref={refS_koukantamasu}
                        defaultValue={25} 
                        type="number"
                        step={0.1}
                        onFocus={(e)=>e.target.select()}
                        mt={2} mb={4} textAlign="right"/>
                     <Text ml={2}>玉</Text>
                  </HStack>

                  <Text as="label" fontWeight="bold" m={3}>持ち玉比率</Text>
                  <HStack>
                     <Input 
                        ref={refS_motidamahiritu}
                        defaultValue={60} 
                        type="number"
                        step={0.01}
                        onFocus={(e)=>e.target.select()}
                        mt={2} mb={4} textAlign="right"/>
                     <Text ml={2}>％</Text>
                  </HStack>

                  <Text as="label" fontWeight="bold" m={3}>回転数/1000円</Text>
                  <HStack>
                     <Input 
                        ref={refS_kaitenritu}
                        defaultValue={20} 
                        type="number"
                        step={0.01}
                        onFocus={(e)=>e.target.select()}
                        mt={2} mb={4} textAlign="right"/>
                     <Text ml={2}>回</Text>
                  </HStack>

                  <Text as="label" fontWeight="bold" m={3}>通常回転数</Text>
                  <HStack>
                     <Input 
                        ref={refS_tujyoukaitensu}
                        defaultValue={1000} 
                        type="number"
                        onFocus={(e)=>e.target.select()}
                        mt={2} mb={4} textAlign="right"/>
                     <Text ml={2}>回</Text>
                  </HStack>

                  <Center>
                     <Button 
                        w={"50%"} 
                        my={2}
                        leftIcon={<Icon as={CgCalculator} mr={2}/>} 
                        colorScheme="teal" 
                        variant="solid"
                        onClick={buttonsimplekitaiti_click}
                     >
                        計算する
                     </Button>
                  </Center>
               </Box>

               {kitaichiCalResult}

            </Box>
         </Box>
      </>
   )
};

const YutimeTool = ({
   get_pachi_notYutime_atrisyubetu, 
   get_pachi_notYutime_kisyu_seinou, 
   get_pachi_Yutime_atrisyubetu, 
   get_pachi_Yutime_kisyu_seinou}) => {

   
   const [YutimeKitaiti, setYutimeKitaiti] = useState();
   const ref_inY_yutimeNokori_kaitensu = useRef(null);
   const ref_inY_kaiten_ritu = useRef(null);
   const ref_inY_minRoundDedam = useRef(null);
   const ref_inY_koukan_tamasu = useRef(null);
   const ref_inY_dennsapozougenn = useRef(null);
   const ref_inY_koatarirushzougenn = useRef(null);
   const [radioval, setRadioval] = useState("1")

   const [YutimeKitaiti0, setYutimeKitaiti0] = useState();
   const ref_inY_yutimeNokori_kaitensu0 = useRef(null);
   const ref_inY_kaiten_ritu0 = useRef(null);
   const ref_inY_minRoundDedam0 = useRef(null);
   const ref_inY_koukan_tamasu0 = useRef(null);
   const ref_inY_tujyoudensapozougenn0 = useRef(null);
   const ref_inY_totuzenjitanzougenn0 = useRef(null);
   const ref_inY_yutimedensapozougenn0 = useRef(null);
   const ref_inY_Kakuhendensapozougenn0 = useRef(null);
   const ref_inY_koatarirushzougenn0 = useRef(0);
   const [radioval0, setRadioval0] = useState("1")

   if(!get_pachi_notYutime_atrisyubetu) return "";
   if(!get_pachi_notYutime_kisyu_seinou) return "";
   if(!get_pachi_Yutime_atrisyubetu) return "";
   if(!get_pachi_Yutime_kisyu_seinou) return "";

   const pachi_notYutime_atrisyubetu = 
      get_pachi_notYutime_atrisyubetu
      .split("|")
      .map( 
         val => val
         .split(",")
         .map((val,index) => {
            if(index==0) {
               return val
            }else{
               return parseFloat(val)
            }
         })
      );
   // console.log("pachi_notYutime_atrisyubetu : ");
   // console.log(pachi_notYutime_atrisyubetu);

   const pachi_notYutime_kisyu_seinou = 
      get_pachi_notYutime_kisyu_seinou
      .split(",")
      .map( val=> parseFloat(val));
   // console.log("pachi_notYutime_kisyu_seinou : ");
   // console.log(pachi_notYutime_kisyu_seinou);

   const pachi_Yutime_atrisyubetu = 
      get_pachi_Yutime_atrisyubetu
      .split("|")
      .map( 
         val => val
         .split(",")
         .map((val,index) => {
            if(index==0) {
               return val
            }else{
               return parseFloat(val)
            }
         })
      );
   // console.log("pachi_Yutime_atrisyubetu : ");
   // console.log(pachi_Yutime_atrisyubetu);

   const pachi_Yutime_kisyu_seinou = 
      get_pachi_Yutime_kisyu_seinou
      .split(",")
      .map( val=> parseFloat(val));
   // console.log("pachi_Yutime_kisyu_seinou : ");
   // console.log(pachi_Yutime_kisyu_seinou);

   const hajimename = pachi_notYutime_atrisyubetu[0][0];
   const hajimededama = pachi_notYutime_atrisyubetu[0][1];

   var 遊タイムなし実質初当たり確率 = 0;
   var 遊タイムなし実質出玉有のみ平均連チャン数 = 0;
   var 遊タイムなし電サポ比率 = 0;
   var 遊タイムなし通常時短電サポ比率 = 0;
   var 遊タイムなし突然時短電サポ比率 = 0;
   var 遊タイムなし遊タイム時短電サポ比率 = 0;
   var 遊タイムなし小当りRUSH比率 = 0;
   var 遊タイムなし確変電サポ比率 = 0;
   var 遊タイムなし最小ラウンド出玉数割平均出玉 = 0;

   switch (pachi_notYutime_kisyu_seinou.length) {
      case 9:
         遊タイムなし実質初当たり確率 = pachi_notYutime_kisyu_seinou[0];
         遊タイムなし実質出玉有のみ平均連チャン数 = pachi_notYutime_kisyu_seinou[1];
         遊タイムなし電サポ比率 = pachi_notYutime_kisyu_seinou[2];
         遊タイムなし通常時短電サポ比率 = pachi_notYutime_kisyu_seinou[3];
         遊タイムなし突然時短電サポ比率 = pachi_notYutime_kisyu_seinou[4];
         遊タイムなし遊タイム時短電サポ比率 = pachi_notYutime_kisyu_seinou[5];
         遊タイムなし小当りRUSH比率 = pachi_notYutime_kisyu_seinou[6];
         遊タイムなし確変電サポ比率 = pachi_notYutime_kisyu_seinou[7];
         遊タイムなし最小ラウンド出玉数割平均出玉 = pachi_notYutime_kisyu_seinou[8];
         break;
      case 8:
         遊タイムなし実質初当たり確率 = pachi_notYutime_kisyu_seinou[0];
         遊タイムなし実質出玉有のみ平均連チャン数 = pachi_notYutime_kisyu_seinou[1];
         遊タイムなし電サポ比率 = pachi_notYutime_kisyu_seinou[2];
         遊タイムなし通常時短電サポ比率 = pachi_notYutime_kisyu_seinou[3];
         遊タイムなし突然時短電サポ比率 = pachi_notYutime_kisyu_seinou[4];
         遊タイムなし遊タイム時短電サポ比率 = pachi_notYutime_kisyu_seinou[5];
         遊タイムなし小当りRUSH比率 = pachi_notYutime_kisyu_seinou[6];
         遊タイムなし最小ラウンド出玉数割平均出玉 = pachi_notYutime_kisyu_seinou[7];
         break;
      case 5:
         遊タイムなし実質初当たり確率 = pachi_notYutime_kisyu_seinou[0];
         遊タイムなし実質出玉有のみ平均連チャン数 = pachi_notYutime_kisyu_seinou[1];
         遊タイムなし電サポ比率 = pachi_notYutime_kisyu_seinou[2];
         遊タイムなし小当りRUSH比率 = pachi_notYutime_kisyu_seinou[3];
         遊タイムなし最小ラウンド出玉数割平均出玉 = pachi_notYutime_kisyu_seinou[4];
         break;
   }

   var 遊タイムあり実質出玉有のみ平均連チャン数 = 0;
   var 遊タイムあり平均通常回転数 = 0;
   var 遊タイムあり平均電サポ回転数 = 0;
   var 遊タイムあり通常時短電サポ回転数 = 0;
   var 遊タイムあり突然時短電サポ回転数 = 0;
   var 遊タイムあり遊タイム時短電サポ回転数 = 0;
   var 遊タイムあり小当りRUSH回転数 = 0;
   var 遊タイムあり確変電サポ回転数 = 0;
   var 遊タイムあり最小ラウンド出玉数割平均出玉 = 0;
   
   switch (pachi_Yutime_kisyu_seinou.length) {
      case 9:
         遊タイムあり実質出玉有のみ平均連チャン数 = pachi_Yutime_kisyu_seinou[0];
         遊タイムあり平均通常回転数 = pachi_Yutime_kisyu_seinou[1];
         遊タイムあり平均電サポ回転数 = pachi_Yutime_kisyu_seinou[2];
         遊タイムあり通常時短電サポ回転数 = pachi_Yutime_kisyu_seinou[3];
         遊タイムあり突然時短電サポ回転数 = pachi_Yutime_kisyu_seinou[4];
         遊タイムあり遊タイム時短電サポ回転数 = pachi_Yutime_kisyu_seinou[5];
         遊タイムあり小当りRUSH回転数 = pachi_Yutime_kisyu_seinou[6];
         遊タイムあり確変電サポ回転数 = pachi_Yutime_kisyu_seinou[7];
         遊タイムあり最小ラウンド出玉数割平均出玉 = pachi_Yutime_kisyu_seinou[8];
         break;
      case 8:
         遊タイムあり実質出玉有のみ平均連チャン数 = pachi_Yutime_kisyu_seinou[0];
         遊タイムあり平均通常回転数 = pachi_Yutime_kisyu_seinou[1];
         遊タイムあり平均電サポ回転数 = pachi_Yutime_kisyu_seinou[2];
         遊タイムあり通常時短電サポ回転数 = pachi_Yutime_kisyu_seinou[3];
         遊タイムあり突然時短電サポ回転数 = pachi_Yutime_kisyu_seinou[4];
         遊タイムあり遊タイム時短電サポ回転数 = pachi_Yutime_kisyu_seinou[5];
         遊タイムあり小当りRUSH回転数 = pachi_Yutime_kisyu_seinou[6];
         遊タイムあり最小ラウンド出玉数割平均出玉 = pachi_Yutime_kisyu_seinou[7];
         break;
      case 5:
         遊タイムあり実質出玉有のみ平均連チャン数 = pachi_Yutime_kisyu_seinou[0];
         遊タイムあり平均通常回転数 = pachi_Yutime_kisyu_seinou[1];
         遊タイムあり平均電サポ回転数 = pachi_Yutime_kisyu_seinou[2];
         遊タイムあり小当りRUSH回転数 = pachi_Yutime_kisyu_seinou[3];
         遊タイムあり最小ラウンド出玉数割平均出玉 = pachi_Yutime_kisyu_seinou[4];
         break;
   }

   function buttonYutimeKitaiti_click(){
      var inY_yutimeNokori_kaitensu= ref_inY_yutimeNokori_kaitensu.current.value;
      var inY_kaiten_ritu= ref_inY_kaiten_ritu.current.value;
      var inY_koukan_tamasu= ref_inY_koukan_tamasu.current.value;
      var inY_dennsapozougenn= ref_inY_dennsapozougenn.current.value;
      var inY_koatarirushzougenn= ref_inY_koatarirushzougenn.current ? ref_inY_koatarirushzougenn.current.value : 0;
      var inY_minRoundDedam= ref_inY_minRoundDedam.current.value;
      var inY_radioval = radioval;

      var 残り回転数で当たる確率 = 1-Math.pow(1-1/遊タイムなし実質初当たり確率,inY_yutimeNokori_kaitensu);
      var 残り回転数で当たらない確率 = Math.pow(1-1/遊タイムなし実質初当たり確率,inY_yutimeNokori_kaitensu);
      var 平均自力回転数 = 遊タイムなし実質初当たり確率*残り回転数で当たる確率;
      // console.log("残り回転数で当たる確率 : ")
      // console.log(残り回転数で当たる確率)
      // console.log("残り回転数で当たらない確率 : ")
      // console.log(残り回転数で当たらない確率)
      // console.log("平均自力回転数 : ")
      // console.log(平均自力回転数)

      var 平均電サポ回転数 = 平均自力回転数*遊タイムなし電サポ比率;
      var 平均小当りRUSH回転数 = 平均自力回転数*遊タイムなし小当りRUSH比率;
      // console.log("平均電サポ回転数 : ")
      // console.log(平均電サポ回転数)
      // console.log("平均小当りRUSH回転数 : ")
      // console.log(平均小当りRUSH回転数)

      var 平均獲得玉数 = 
         (遊タイムなし実質出玉有のみ平均連チャン数 * 残り回転数で当たる確率)
         * (inY_minRoundDedam * 遊タイムなし最小ラウンド出玉数割平均出玉)
         + 平均小当りRUSH回転数 * inY_koatarirushzougenn;
      // console.log("遊タイムなし実質出玉有のみ平均連チャン数 : ")
      // console.log(遊タイムなし実質出玉有のみ平均連チャン数)
      // console.log("残り回転数で当たる確率 : ")
      // console.log(残り回転数で当たる確率)
      // console.log("inY_minRoundDedam : ")
      // console.log(inY_minRoundDedam)
      // console.log("遊タイムなし最小ラウンド出玉数割平均出玉 : ")
      // console.log(遊タイムなし最小ラウンド出玉数割平均出玉)
      // console.log("平均小当りRUSH回転数 : ")
      // console.log(平均小当りRUSH回転数)
      // console.log("inY_koatarirushzougenn : ")
      // console.log(inY_koatarirushzougenn)
      // console.log("平均獲得玉数 : ")
      // console.log(平均獲得玉数)

      var 平均使用玉数 = 
         平均自力回転数/inY_kaiten_ritu *250
         - 平均電サポ回転数 * inY_dennsapozougenn;
      // console.log("平均使用玉数 : ")
      // console.log(平均使用玉数)
      
      var 平均獲得円 = 平均獲得玉数/inY_koukan_tamasu* 100;
      var 平均使用円 = 平均使用玉数/25* 100;
      var 遊タイムなし期待値 = 平均獲得円 - 平均使用円;
      var 遊タイムなし期待値持ち玉 = (平均獲得玉数 - 平均使用玉数)/inY_koukan_tamasu* 100;
      // console.log("平均獲得円 : ")
      // console.log(平均獲得円)
      // console.log("平均使用円 : ")
      // console.log(平均使用円)
      // console.log("遊タイムなし期待値 : ")
      // console.log(遊タイムなし期待値)
      // console.log("遊タイムなし期待値持ち玉 : ")
      // console.log(遊タイムなし期待値持ち玉)

      var 遊タイムあり平均獲得玉数 = 
         遊タイムあり実質出玉有のみ平均連チャン数
         * (inY_minRoundDedam*遊タイムあり最小ラウンド出玉数割平均出玉)
         +  遊タイムあり小当りRUSH回転数 * inY_koatarirushzougenn;
      var 遊タイムあり平均使用玉数 = 
         遊タイムあり平均通常回転数/inY_kaiten_ritu*250 
         - 遊タイムあり平均電サポ回転数 * inY_dennsapozougenn;
      // console.log("遊タイムあり平均獲得玉数 : ")
      // console.log(遊タイムあり平均獲得玉数)
      // console.log("遊タイムあり平均使用玉数 : ")
      // console.log(遊タイムあり平均使用玉数)

      var 遊タイムあり平均獲得円 = 遊タイムあり平均獲得玉数/inY_koukan_tamasu* 100;
      var 遊タイムあり平均使用円 = 遊タイムあり平均使用玉数/25* 100;
      var 遊タイムあり期待値 = 遊タイムあり平均獲得円 - 遊タイムあり平均使用円;
      var 遊タイムあり期待値持ち玉 = (遊タイムあり平均獲得玉数 - 遊タイムあり平均使用玉数)/inY_koukan_tamasu* 100;
      // console.log("遊タイムあり平均獲得円 : ")
      // console.log(遊タイムあり平均獲得円)
      // console.log("遊タイムあり平均使用円 : ")
      // console.log(遊タイムあり平均使用円)
      // console.log("遊タイムあり期待値 : ")
      // console.log(遊タイムあり期待値)
      // console.log("遊タイムあり期待値持ち玉 : ")
      // console.log(遊タイムあり期待値持ち玉)

      var all現金 = 遊タイムなし期待値*残り回転数で当たる確率 + 遊タイムあり期待値*残り回転数で当たらない確率;
      var all持ち玉 = 遊タイムなし期待値持ち玉*残り回転数で当たる確率 + 遊タイムあり期待値持ち玉*残り回転数で当たらない確率;
      // console.log("all現金 : ")
      // console.log(all現金)
      // console.log("all持ち玉 : ")
      // console.log(all持ち玉)

      var all = "";
      if(inY_radioval == "1"){
         all = all現金;
      }else{
         all = all持ち玉;
      }
      
      setYutimeKitaiti(
         <Box m={3}>
            <Center>■期待収支金額</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(all, 2)}円
            </Center>
            <Center>到達率</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(残り回転数で当たらない確率*100, 1)}%
            </Center>
            <Center>平均自力回転数</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(平均自力回転数, 1)}回
            </Center>
         </Box>
      );
   }

   function buttonYutimeKitaiti_detail__click(){
      var inY_yutimeNokori_kaitensu= ref_inY_yutimeNokori_kaitensu0.current.value;
      var inY_kaiten_ritu= ref_inY_kaiten_ritu0.current.value;
      var inY_koukan_tamasu= ref_inY_koukan_tamasu0.current.value;
      var inY_tujyoudensapozougenn= ref_inY_tujyoudensapozougenn0.current ? ref_inY_tujyoudensapozougenn0.current.value : 0;
      var inY_totuzenjitanzougenn= ref_inY_totuzenjitanzougenn0.current ? ref_inY_totuzenjitanzougenn0.current.value : 0;
      var inY_yutimedensapozougenn= ref_inY_yutimedensapozougenn0.current ? ref_inY_yutimedensapozougenn0.current.value : 0;
      var inY_Kakuhendensapozougenn= ref_inY_Kakuhendensapozougenn0.current ? ref_inY_Kakuhendensapozougenn0.current.value : 0;
      var inY_koatarirushzougenn= ref_inY_koatarirushzougenn0.current ? ref_inY_koatarirushzougenn0.current.value : 0;
      var inY_minRoundDedam= ref_inY_minRoundDedam0.current.value;
      var inY_radioval = radioval0;

      var 残り回転数で当たる確率 = 1-Math.pow(1-1/遊タイムなし実質初当たり確率,inY_yutimeNokori_kaitensu);
      var 残り回転数で当たらない確率 = Math.pow(1-1/遊タイムなし実質初当たり確率,inY_yutimeNokori_kaitensu);
      var 平均自力回転数 = 遊タイムなし実質初当たり確率*残り回転数で当たる確率;
      // console.log("残り回転数で当たる確率 : ")
      // console.log(残り回転数で当たる確率)
      // console.log("残り回転数で当たらない確率 : ")
      // console.log(残り回転数で当たらない確率)
      // console.log("平均自力回転数 : ")
      // console.log(平均自力回転数)

      var 平均電サポ回転数 = 平均自力回転数*遊タイムなし電サポ比率;
      var 平均通常時短電サポ回転数 = 平均自力回転数*遊タイムなし通常時短電サポ比率;
      var 平均突然時短電サポ回転数 = 平均自力回転数*遊タイムなし突然時短電サポ比率;
      var 平均遊タイム時短電サポ回転数 = 平均自力回転数*遊タイムなし遊タイム時短電サポ比率;
      var 平均確変電サポ回転数 = 平均自力回転数*遊タイムなし確変電サポ比率;
      var 平均小当りRUSH回転数 = 平均自力回転数*遊タイムなし小当りRUSH比率;
      // console.log("平均電サポ回転数 : ")
      // console.log(平均電サポ回転数)
      // console.log("平均通常時短電サポ回転数 : ")
      // console.log(平均通常時短電サポ回転数)
      // console.log("平均突然時短電サポ回転数 : ")
      // console.log(平均突然時短電サポ回転数)
      // console.log("平均遊タイム時短電サポ回転数 : ")
      // console.log(平均遊タイム時短電サポ回転数)
      // console.log("平均確変電サポ回転数 : ")
      // console.log(平均確変電サポ回転数)
      // console.log("平均小当りRUSH回転数 : ")
      // console.log(平均小当りRUSH回転数)

      var 平均獲得玉数 = 
         (遊タイムなし実質出玉有のみ平均連チャン数 * 残り回転数で当たる確率)
         * (inY_minRoundDedam * 遊タイムなし最小ラウンド出玉数割平均出玉)
         + 平均小当りRUSH回転数 * inY_koatarirushzougenn;
      // console.log("平均獲得玉数 : ")
      // console.log(平均獲得玉数)

      var 平均使用玉数 = 
         平均自力回転数/inY_kaiten_ritu *250
         - 平均通常時短電サポ回転数 * inY_tujyoudensapozougenn
         - 平均突然時短電サポ回転数 * inY_totuzenjitanzougenn
         - 平均遊タイム時短電サポ回転数 * inY_yutimedensapozougenn
         - 平均確変電サポ回転数 * inY_Kakuhendensapozougenn;
      // console.log("平均使用玉数 : ")
      // console.log(平均使用玉数)

      var 平均獲得円 = 平均獲得玉数/inY_koukan_tamasu* 100;
      var 平均使用円 = 平均使用玉数/25* 100;
      var 遊タイムなし期待値 = 平均獲得円 - 平均使用円;
      var 遊タイムなし期待値持ち玉 = (平均獲得玉数 - 平均使用玉数)/inY_koukan_tamasu* 100;
      // console.log("平均獲得円 : ")
      // console.log(平均獲得円)
      // console.log("平均使用円 : ")
      // console.log(平均使用円)
      // console.log("遊タイムなし期待値 : ")
      // console.log(遊タイムなし期待値)
      // console.log("遊タイムなし期待値持ち玉 : ")
      // console.log(遊タイムなし期待値持ち玉)

      var 遊タイムあり平均獲得玉数 = 
         遊タイムあり実質出玉有のみ平均連チャン数
         * (inY_minRoundDedam*遊タイムあり最小ラウンド出玉数割平均出玉)
         + 遊タイムあり小当りRUSH回転数 * inY_koatarirushzougenn;
      var 遊タイムあり平均使用玉数 = 
         遊タイムあり平均通常回転数/inY_kaiten_ritu*250
         - 遊タイムあり通常時短電サポ回転数 * inY_tujyoudensapozougenn
         - 遊タイムあり突然時短電サポ回転数 * inY_totuzenjitanzougenn
         - 遊タイムあり遊タイム時短電サポ回転数 * inY_yutimedensapozougenn
         - 遊タイムあり確変電サポ回転数 * inY_Kakuhendensapozougenn;
      // console.log("遊タイムあり平均獲得玉数 : ")
      // console.log(遊タイムあり平均獲得玉数)
      // console.log("遊タイムあり平均使用玉数 : ")
      // console.log(遊タイムあり平均使用玉数)

      var 遊タイムあり平均獲得円 = 遊タイムあり平均獲得玉数/inY_koukan_tamasu* 100;
      var 遊タイムあり平均使用円 = 遊タイムあり平均使用玉数/25* 100;
      var 遊タイムあり期待値 = 遊タイムあり平均獲得円 - 遊タイムあり平均使用円;
      var 遊タイムあり期待値持ち玉 = (遊タイムあり平均獲得玉数 - 遊タイムあり平均使用玉数)/inY_koukan_tamasu* 100;
      // console.log("遊タイムあり平均獲得円 : ")
      // console.log(遊タイムあり平均獲得円)
      // console.log("遊タイムあり平均使用円 : ")
      // console.log(遊タイムあり平均使用円)
      // console.log("遊タイムあり期待値 : ")
      // console.log(遊タイムあり期待値)
      // console.log("遊タイムあり期待値持ち玉 : ")
      // console.log(遊タイムあり期待値持ち玉)

      var all現金 = 遊タイムなし期待値*残り回転数で当たる確率 + 遊タイムあり期待値*残り回転数で当たらない確率;
      var all持ち玉 = 遊タイムなし期待値持ち玉*残り回転数で当たる確率 + 遊タイムあり期待値持ち玉*残り回転数で当たらない確率;

      var all = "";
      if(inY_radioval == "1"){
         all = all現金;
      }else{
         all = all持ち玉;
      }
      // console.log("all現金 : ")
      // console.log(all現金)
      // console.log("all持ち玉 : ")
      // console.log(all持ち玉)

      setYutimeKitaiti0(
         <Box m={3}>
            <Center>■期待収支金額</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(all, 2)}円
            </Center>
            <Center>到達率</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(残り回転数で当たらない確率*100, 1)}%
            </Center>
            <Center>平均自力回転数</Center>
            <Center color={"red"} fontWeight={"bold"} fontSize={"2xl"}>
               {floatFormat1(平均自力回転数, 1)}回
            </Center>
         </Box>
      );
   }

   return (
      <>
      <Box m={"5px"} w="98%">
      <Tabs variant="unstyled">
         <TabList>
            <Tab _selected={{ color: "white", bg: "blue.500" }} bg="gray.300" mx={1}>シンプル</Tab>
            {
            pachi_Yutime_kisyu_seinou.length >= 8 ? 
            <Tab _selected={{ color: "white", bg: "blue.500" }} bg="gray.300" mx={1}>サポ詳細</Tab>:""
            }
         </TabList>
         <TabPanels p={0}>
            <TabPanel p={0}>
               <Box>
                  <Box as="fieldset" borderStyle="groove" borderWidth="2px">
                     <Box as="legend" p={3} ml={6} mb={0}>遊タイム期待収支計算(シンプル)</Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>残り回転数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_yutimeNokori_kaitensu}
                              defaultValue={100} 
                              type="number"
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>回</Text>
                        </HStack>
                     </Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>回転数/1000円</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_kaiten_ritu}
                              defaultValue={20} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>回</Text>
                        </HStack>
                     </Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>{hajimename}出玉</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_minRoundDedam}
                              defaultValue={hajimededama} 
                              type="number"
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>交換玉数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_koukan_tamasu}
                              defaultValue={25} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>電サポ時・増減数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_dennsapozougenn}
                              defaultValue={0}
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>
                     {
                     遊タイムなし小当りRUSH比率 > 0 ?
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>小当RUSH時・増減数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_koatarirushzougenn}
                              defaultValue={0} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>:""
                     }
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>投資分</Text>
                        <RadioGroup 
                           defaultValue={radioval} 
                           onChange={val  => setRadioval(val)}
                           mt={2} mb={4}>
                           <Stack spacing={5} direction="row">
                              <Radio colorScheme="red" value="1">
                                 現金
                              </Radio>
                              <Radio colorScheme="green" value="2">
                                 持ち玉
                              </Radio>
                           </Stack>
                        </RadioGroup>
                     </Box>

                     <Center>
                        <Button 
                           w={"50%"} 
                           my={2}
                           leftIcon={<Icon as={CgCalculator} mr={2}/>} 
                           colorScheme="teal" 
                           variant="solid"
                           onClick={buttonYutimeKitaiti_click}
                        >
                           計算する
                        </Button>
                     </Center>

                     {YutimeKitaiti}

                  </Box>
               </Box>
            </TabPanel>

            <TabPanel p={0}>
               <Box>
                  <Box as="fieldset" borderStyle="groove" borderWidth="2px">
                     <Box as="legend" p={3} ml={6} mb={0}>遊タイム期待収支計算(サポ詳細)</Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>残り回転数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_yutimeNokori_kaitensu0}
                              defaultValue={100} 
                              type="number"
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>回</Text>
                        </HStack>
                     </Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>回転数/1000円</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_kaiten_ritu0}
                              defaultValue={20} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>回</Text>
                        </HStack>
                     </Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>{hajimename}出玉</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_minRoundDedam0}
                              defaultValue={hajimededama} 
                              type="number"
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>交換玉数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_koukan_tamasu0}
                              defaultValue={25} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>
                     {
                     遊タイムなし通常時短電サポ比率 > 0 ?
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>通常時短時・電サポ増減数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_tujyoudensapozougenn0}
                              defaultValue={0} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>:""
                     }
                     {
                     遊タイムなし突然時短電サポ比率 > 0 ?
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>突然時短時・電サポ増減数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_totuzenjitanzougenn0}
                              defaultValue={0} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>:""
                     }
                     {
                     遊タイムあり遊タイム時短電サポ回転数 > 0 ?
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>遊タイム時・電サポ増減数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_yutimedensapozougenn0}
                              defaultValue={0} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>:""
                     }
                     {
                     遊タイムなし確変電サポ比率 > 0 ?
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>確変時・電サポ増減数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_Kakuhendensapozougenn0}
                              defaultValue={0} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>:""
                     }
                     {
                     遊タイムなし小当りRUSH比率 > 0 ?
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>小当RUSH時・増減数</Text>
                        <HStack>
                           <Input 
                              ref={ref_inY_koatarirushzougenn0}
                              defaultValue={0} 
                              type="number"
                              step={0.01}
                              onFocus={(e)=>e.target.select()}
                              mt={2} mb={4} textAlign="right"/>
                           <Text ml={2}>玉</Text>
                        </HStack>
                     </Box>:""
                     }
                     <Box m={3}>
                        <Text as="label" fontWeight="bold" m={3}>投資分</Text>
                        <RadioGroup 
                           defaultValue={radioval0} 
                           onChange={val  => setRadioval0(val)}
                           mt={2} mb={4}>
                           <Stack spacing={5} direction="row">
                              <Radio colorScheme="red" value="1">
                                 現金
                              </Radio>
                              <Radio colorScheme="green" value="2">
                                 持ち玉
                              </Radio>
                           </Stack>
                        </RadioGroup>
                     </Box>

                     <Center>
                        <Button 
                           w={"50%"} 
                           my={2}
                           leftIcon={<Icon as={CgCalculator} mr={2}/>} 
                           colorScheme="teal" 
                           variant="solid"
                           onClick={buttonYutimeKitaiti_detail__click}
                        >
                           計算する
                        </Button>
                     </Center>

                     {YutimeKitaiti0}

                  </Box>
               </Box>
            </TabPanel>

         </TabPanels>
      </Tabs>
      </Box>
      </>
   )

}



const BlogIndex = ({data, path, pageContext: { nextPostUrl, previousPostUrl }}) => {
   
   const ret = useAxiosTest();

   let post = data.post;
   let title = post.title ?? "" 
   let content = post.content ?? "" 
   
   const uri = post.uri;
   const date = post.date;
   const datemodi = post.modified;
   const excerpt = post.excerpt;
   const categories = post.categories.nodes;
   const tags = post.tags.nodes;
   const author = post.author.node;
   let categrizetext = post.pachiACF.categrizetext ?? "kara" 

   // console.log(content);
   content = content.replace(
      /<p>\[pachi_befor_sentence\]<\/p>/g,
      ""
   );
   content = content.replace(
      /<p>\[toc\]<\/p>/g,
      ""
   );

   content = content.replace(
      /<h2>計算ツール<\/h2>/g,
      ""
   );
   content = content.replace(
      /<p>「<a href="\/how-to-calc-tool" target="_blank" rel="noopener">計算ツールの使い方<\/a>」<\/p>/g,
      ""
   );
   content = content.replace(
      /<p>\[calctool\]<\/p>/g,
      ""
   );
   content = content.replace(
      /<h2>関連記事<\/h2>/g,
      ""
   );
   content = content.replace(
      /<p>\[cFiltering_kannrennkiji\]<\/p>/g,
      ""
   );
   content = content.replace(
      /<h2>設置店舗検索\(パチトラ提供\)<\/h2>/g,
      ""
   );
   content = content.replace(
      /<p>\[pachitorasearch\]<\/p>/g,
      ""
   );
   content = content.replace(
      /<p>\[holl_befor_sentence\]<\/p>/g,
      ""
   );
   content = content.replace(
      /\[wp_emoji2.+?\]/g,
      ""
   );
   content = content.replace(
      /<p>\[pachitorasearch\]<\/p>/g,
      ""
   );
   content = content.replace(
      /";>/g,
      "\">"
   );
   
   var newContent = [];
   
   Parser(content,{ trim: true }).forEach( (con) => {
      var flag0 = false;
      var flag1 = false;
      var flag2 = false;
      if(con.type){
         
         if(con.type == "p"){
            // console.log(con)
            if(con.props.children){
               if(con.props.children == "[calcbodar]"){
                  flag0 = true;
               }
               if(con.props.children == "[calcYutime]"){
                  flag1 = true;
               }
               if(con.props.children.type){
                  if(con.props.children.type == "iframe"){
                     flag2 = true;
                  }
               }
            }
         }
      }
      
      if(flag0){
         newContent.push(
            <KeisanTool 
               get_pachi_kisyu_seinou={post.pachiACF.pachiKisyuSeinou} 
               get_pachi_atrisyubetu={post.pachiACF.pachiAtrisyubetu}
               key="KeisanTool"
               
            />
         );
      }else if(flag1){
         newContent.push(
            <YutimeTool 
               get_pachi_notYutime_atrisyubetu={post.pachiACF.pachiNotyutimeAtrisyubetu} 
               get_pachi_notYutime_kisyu_seinou={post.pachiACF.pachiNotyutimeKisyuSeinou}
               get_pachi_Yutime_atrisyubetu={post.pachiACF.pachiYutimeAtrisyubetu} 
               get_pachi_Yutime_kisyu_seinou={post.pachiACF.pachiYutimeKisyuSeinou}
               key="YutimeTool"
            />
         );
      }else if(flag2){
         newContent.push(
            <div className="iframe">
            {con}
            </div>
         )
      }else{
         newContent.push(con);
      }
   })

   // console.log(post.featuredImage ? post.featuredImage.node.localFile.childImageSharp.fluid.src : "");
   return (
      <>
      <Layout path={path} title={title}>
      <Seo 
         title={title + "|パチンカーズネット"} 
         description={content}
         image={post.featuredImage ? post.featuredImage.node.localFile.childImageSharp.fluid.src : ""}
      />
         <Box m={5}>
            <Heading>{title}</Heading>
            <Box m={2} p={2}>
               <Center m={2}>
               <Wrap spacing={5}>
                  <WrapItem>
                     <HStack>
                        <Icon as={AiOutlineFieldTime} />
                        <Box　
                           textTransform="uppercase" 
                           fontSize="sm" 
                           letterSpacing="wide">
                              {date}に公開
                        </Box>
                     </HStack>
                  </WrapItem>
                  {date != datemodi ? 
                  <WrapItem>
                     <HStack>
                        <Icon as={BsArrowCounterclockwise} />
                        <Box　
                           textTransform="uppercase" 
                           fontSize="sm" 
                           letterSpacing="wide">
                              {datemodi}に更新
                        </Box>
                     </HStack>
                  </WrapItem>
                  : ""}
               </Wrap>
               </Center>
               <Center m={2}>
               <Wrap spacing={5}>
                  <WrapItem>
                     <HStack>
                     <Icon as={AiOutlineApartment} />
                     {categories &&
                        categories.map((cat,index) => {
                           return (
                              <>
                              <Link
                                 as={GatsbyLink}
                                 to={"../category/" + cat.slug +"/1"}
                                 mt={1}
                                 display="block"
                                 fontSize="lg"
                                 lineHeight="normal"
                                 fontWeight="semibold"
                                 href="#"
                              >
                                 <Tag
                                    fontWeight="bold" 
                                    textTransform="uppercase" 
                                    fontSize="sm" 
                                    letterSpacing="wide"
                                 >
                                    {cat.name}
                                 </Tag>
                              </Link>
                              </>
                           )
                        })
                     }
                     </HStack>
                  </WrapItem>
                  {tags.length != 0 ?
                  <WrapItem>
                     <HStack>
                     <Icon as={AiFillTags} />
                     {tags &&
                        tags.map((tag,index)=> {
                           return (
                              <>
                              <Link
                                 as={GatsbyLink}
                                 to={"../tag/" + tag.slug +"/1"}
                                 mt={1}
                                 display="block"
                                 fontSize="lg"
                                 lineHeight="normal"
                                 fontWeight="semibold"
                                 href="#"
                              >
                                 <Tag
                                    fontWeight="bold" 
                                    textTransform="uppercase" 
                                    fontSize="sm" 
                                    letterSpacing="wide"
                                 >
                                    {tag.name}
                                 </Tag>
                              </Link>
                              </>
                           )
                        })
                     }
                     </HStack>
                  </WrapItem>
                  : ""}
                  {author.name != "admin" ? 
                  <WrapItem>
                     <HStack>
                        <Icon as={GrUserManager} />
                        <Box　
                           textTransform="uppercase" 
                           fontSize="sm" 
                           letterSpacing="wide">
                              {author.name}
                        </Box>
                     </HStack>
                  </WrapItem>
                  : ""}
               </Wrap>
               </Center>
            </Box>
            <div className="wordpress">
               {newContent}
            </div>
            
            {/* {previousPostUrl && <Link as={GatsbyLink} to={previousPostUrl}>Previous page</Link> }
            {nextPostUrl && <Link as={GatsbyLink} to={nextPostUrl}>Next page</Link> } */}
         </Box>
      </Layout>
      </>
   )
}

export default BlogIndex

export const postQuery = graphql`
  query BlogPostById(
    $id: String!,
    $previousPostId: String,
    $nextPostId: String,
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "YYYY.MM.DD")
      modified(formatString: "YYYY.MM.DD")
      categories {
         nodes {
           slug
           name
         }
       }
      tags {
         nodes {
           name
           slug
         }
       }
       author {
         node {
           name
           slug
         }
       }
      pachiACF {
        categrizetext
        fieldGroupName
        kensakuE
        kensakuMaker
        pachiAtrisyubetu
        pachiKisyuSeinou
        pachiNotyutimeAtrisyubetu
        pachiNotyutimeKisyuSeinou
        pachiYutimeAtrisyubetu
        pachiYutimeKisyuSeinou
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`